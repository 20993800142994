import React, { Component } from 'react'
import '../../../styles/scss/news.scss'
import axios from 'axios'
import authHeader from '../../../../service/auth-header'
import PublishMenu from '../PublishMenu'
import EditImage from '../EditImage'
import TextareaAutosize from 'react-textarea-autosize';
// import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ConfirmationModal from '../ConfirmationModal'

export default class ArticleEdit extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedArticle: null,
            isSaving: false,
            alreadySaved: true,
            history: [],
            confirmationData: null
        }
    }

    componentDidMount = () => {
        this.isIpValid();

        if (document) {
            this.quill = require('react-quill')
        }
    }

    isIpValid = () => {
        axios.get(`https://versemail.azurewebsites.net/user/`).then(response => {
            if (response) {
                if (JSON.parse(localStorage.getItem('user')) && this.isTokenValid()) {
                    this.setState({
                        isLoading: false
                    });

                    this.getArticle(this.props.slug)
                } else {
                    window.open('/', '_self')
                }
            } else {
                window.open('/', '_self')
            }
        }).catch(error => {
            window.open('/', '_self')
        });
    }

    isTokenValid = () => {
        const token = this.getCurrentToken();
        if (!token || token === undefined)
            return false;
        else if (this.parseJwt(token).exp < Date.now() / 1000) {
            localStorage.clear();
            return false;
        }
        return true;
    }

    getCurrentToken = () => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.accessToken)
            return user.accessToken;
    }

    parseJwt = (token) => {
        try {
            return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
            return {exp: 0};
        }
    };

    getArticle = (slug) => {
        axios.get(`https://versemail.azurewebsites.net/news/article/admin/${slug}`, { headers: authHeader() }).then(response => {
            if (response?.data) {
                this.setState({
                    selectedArticle: response?.data,
                    originalArticle: response?.data,
                    history: []
                })
            }
        });
    }

    renderTagsContainer = (tags) => {
        if (!tags?.length) return;

        return (
            <div className='tags-container'>
                {this.renderTags(tags)}
            </div>
        )
    }

    renderTags = (tags) => {
        return tags.map((tag, index) => {
            return (
                <input type='text' value={tag} className='tag' key={index} placeholder='Add tag...' onChange={(e) => this.handleTagInputChange(e, index)}/>
            )
        })
    }

    getContentParsed = (content) => {
        /*return (
            <TextareaAutosize 
                value={content} 
                onChange={(e) => this.handleInputChange(e)}
                name='content'
                placeholder='Content'
                autoComplete='off'
            />
        )*/

        let modules = {
            toolbar: [
                ['bold', 'italic', 'underline'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                ['link', 'image', 'video'],
            ],
        };

        if (this.quill) {
            const Quill = this.quill;
            return (
                <Quill 
                    className='p'
                    modules={modules}
                    theme="snow" 
                    value={content} 
                    name='content' 
                    onChange={(html) => this.handleInputChange({target: {name: 'content', value: html} })} 
                />
            )
        }
    }

    // case user modifies text then removes it to revert it to where it was 
    checkIfAlreadySaved = (e) => {
        if (!this.state.history?.length) return

        let lastArticle = this.state.history[0];

        if (JSON.stringify(lastArticle) === JSON.stringify(this.state.selectedArticle)) {
            this.setState({
                alreadySaved: true
            })
        }
    }

    handleInputChange = (e) => {
        this.setState({
            selectedArticle: {
                ...this.state.selectedArticle,
                [e.target?.name]: e.target.value
            },
            alreadySaved: false
        }, () => this.checkIfAlreadySaved(e))
    }

    handleTagInputChange = (e, index) => {
        this.setState({
            selectedArticle: {
                ...this.state.selectedArticle,
                tags: this.state.selectedArticle.tags.map((tag, i) => {
                    if (i === index) {
                        tag = e.target.value
                    }

                    return tag
                })
            },
            alreadySaved: false
        }, () => this.checkIfAlreadySaved(e))
    }

    toggleSelectImage = (src = '') => {
        this.setState({
            selectedImageUpdate: {src}
        })
    }

    updateImage = (image) => {
        let event = {
            target: {
                name: 'source',
                value: image
            }
        }
        this.handleInputChange(event)
    }

    cancelAction = () => {
        this.setState({
            confirmationData: null
        })
    }

    handlePublish = () => {
        this.setState({
            confirmationData: {
                message: `${this.state.selectedArticle?.isPublished ? 'unpublish' : 'publish'} this page ?`,
                confirmationAction: this.publishArticle,
                cancelAction: this.cancelAction,
            }
        })
    }

    handleRevert = () => {
        this.setState({
            confirmationData: {
                message: 'revert this page ?',
                confirmationAction: this.revertPage,
                cancelAction: this.cancelAction,
            }
        })
    }

    publishArticle = (article = this.state.selectedArticle, isDisabled = this.state.isPublishing) => {
        if (!article || isDisabled) return;

        article = {
            ...article,
            isPublished: !article?.isPublished,
            isDeleted: false,
        }

        this.setState({
            isPublishing: true
        })

        axios.put(`https://versemail.azurewebsites.net/news/${article?._id}`, {article}, { headers: authHeader() }).then(response => {
            if (response?.data) {
                this.setState({
                    isPublishing: false,
                    isSaving: false,
                    alreadySaved: true,
                    selectedArticle: article,
                    history: [article, ...this.state.history]
                })
            }
        }).catch(error => {
            alert('error or logged off')
            this.setState({
                isPublishing: false
            })
        });
    }

    revertPage = (isDisabled = !this.state.history?.length) => {
        if (isDisabled) return; 
        
        let history = [...this.state.history];
        history.shift();

        this.setState({
            selectedArticle: history[0] || this.state.originalArticle,
            history: history
        }, () => {
            
        })
    }

    saveArticle = (article, isDisabled) => {
        if (!article || isDisabled) return;

        this.setState({
            isSaving: true
        })

        axios.put(`https://versemail.azurewebsites.net/news/${article?._id}`, {article}, { headers: authHeader() }).then(response => {
            if (response?.data) {
                this.setState({
                    isSaving: false,
                    alreadySaved: true,
                    history: [article, ...this.state.history]
                })
            }
        }).catch(error => {
            alert('error or logged off')
            this.setState({
                isSaving: false,
                alreadySaved: false
            })
        });
    }

    renderPublishMenu = () => {
        return (
            <div className='publish-menu' style={{top: '13vh'}}>
                <div className={"button cancel" + (!this.state.history?.length ? ' disabled' : '')} onClick={() => this.revertPage()}>
                    <div>
                        {
                            this.state.isPublishing ?
                                <i className='bx bx-loader-alt bx-spin' /> :
                                <i className='bx bx-undo' />
                        }

                        <p>{this.state.isReverting ? 'CANCELING...' : 'CANCEL'}</p>
                    </div>
                </div>

                <div className={"button " + (this.state.isPublishing ? 'disabled' : '')} onClick={() => this.handlePublish()}>
                    <div>
                        {
                            this.state.isPublishing ? 
                                <i className='bx bx-loader-alt bx-spin' /> :
                                <i className='bx bx-cloud-upload'/>
                        }

                        <p>{this.state.isPublishing ? 'PUBLISHING...' : this.state.selectedArticle?.isPublished ? 'UNPUBLISH' : 'PUBLISH'}</p>
                    </div>
                </div>

                <div    
                    className={"button " + (this.state.isSaving || this.state.alreadySaved ? 'disabled' : '')} 
                    style={{width: '50px', height: '38.5px', background: '#DFDFDF', color: '#393939'}} 
                    onClick={() => this.saveArticle(this.state.selectedArticle, this.state.isSaving || this.state.alreadySaved)}
                >
                    <div>
                        <i class='bx bxs-save' style={{margin: 0, fontSize: '18px'}} />
                    </div>
                </div>
            </div>
        )
    }

    render() {
        let {selectedArticle} = this.state
        return (
            <div className='news article-page edit' id="news">
                {this.renderPublishMenu()}
                <EditImage
                    selectedImage={this.state.selectedImageUpdate} 
                    toggleSelectImage={this.toggleSelectImage}
                    updateImage={this.updateImage}
                />

                <ConfirmationModal
                    confirmationData={this.state.confirmationData}
                />
                <div className='article-container'>
                    <div className='titles'>
                        <input type='text' value={selectedArticle?.date} className='h4' onChange={(e) => this.handleInputChange(e)} name='date'/>
                        <TextareaAutosize
                            className={'title h3'}
                            value={selectedArticle?.title}
                            name='title'
                            placeholder='Title'
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                        />
                        <TextareaAutosize
                            className={'title p'}
                            value={selectedArticle?.subtitle}
                            name='subtitle'
                            placeholder='Subtitle'
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}

                        />
                        {this.renderTagsContainer(selectedArticle?.tags)}
                    </div>

                    <div className='image' style={{backgroundImage: `url(${selectedArticle?.source})`}} onClick={() => this.toggleSelectImage(selectedArticle?.source || '.')} />
                    <div className='article-content'>
                        <TextareaAutosize 
                            className='first-paragraph' 
                            onChange={(e) => this.handleInputChange(e)}
                            placeholder='First paragraph'
                            value={selectedArticle?.firsParagraph?.trim() || ''}
                            name='firsParagraph'
                        />
                        
                        <div className='content'>
                            {this.getContentParsed(selectedArticle?.content)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
