import axios from 'axios';
import React, { Component } from 'react'
import authHeader from '../../../service/auth-header';
import ConfirmationModal from './ConfirmationModal';

export default class PublishMenu extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        isPublishing: false,
        confirmationData: null
      }
    }

    publishPage = (pageName = this.props.page?.name, page = this.props.page) => {
        if (!pageName) return;

        this.setState({
            isPublishing: true
        })

        axios.post(`https://versemail.azurewebsites.net/page/`, {page, pageName}, { headers: authHeader() }).then(response => {
            if (response?.data) {
                this.setState({
                    isPublishing: false
                })
            }
        }).catch(error => {
            alert('error or logged off')
            this.setState({
                isPublishing: false
            })
        });
    }

    revertPage = () => {
        this.props.revertPage();
        this.cancelAction();
    }

    cancelAction = () => {
        this.setState({
            confirmationData: null
        })
    }

    handlePublish = () => {
        this.setState({
            confirmationData: {
                message: 'publish this page ?',
                confirmationAction: this.publishPage,
                cancelAction: this.cancelAction,
            }
        })
    }

    handleRevert = () => {
        this.setState({
            confirmationData: {
                message: 'revert this page ?',
                confirmationAction: this.revertPage,
                cancelAction: this.cancelAction,
            }
        })
    }

    render() {
        return (
            <div className='publish-menu'>
                <ConfirmationModal
                    confirmationData={this.state.confirmationData}
                />
                <div className="button cancel" onClick={() => this.handleRevert(this.props.page?.name)}>
                    <div>
                        {
                            this.state.isPublishing ?
                                <i className='bx bx-loader-alt bx-spin' /> :
                                <i className='bx bx-undo' />
                        }

                        <p>{this.props.isReverting ? 'REVERTING...' : 'REVERT'}</p>
                    </div>
                </div>

                <div className="button" onClick={() => this.handlePublish()}>
                    <div>
                        {
                            this.state.isPublishing ? 
                                <i className='bx bx-loader-alt bx-spin' /> :
                                <i className='bx bx-cloud-upload'/>
                        }

                        <p>{this.state.isPublishing ? 'PUBLISHING...' : 'PUBLISH'}</p>
                    </div>
                </div>
            </div>
        )
    }
}
