import React, { Component } from 'react'
import Article from '../../../components/Article'
import Footer from '../../../components/Common/Footer';
import Header from '../../../components/Common/Header';
import SEO from '../../../components/Common/SEO';
import ArticleEdit from '../../../components/Edit/interfaces/AricleEdit';

const Page = ({ location }) => {
    const url = location.pathname ? location.pathname : '';
    let paths = url.split('/article/'), slug;

    if (paths?.length >= 1) {
        slug = paths[1]
    }

    return (
        <div style={{overflow: 'hidden', overflowY: 'scroll', height: '100vh'}}>
            <Header isTransparent={false} isLoaded={true} threshold={0.01}/>
            <SEO
                title='Article about Verse Estate'
            />
            <div className='snap'>
                <ArticleEdit slug={slug} />
            </div>
            <Footer/>
        </div>
    )
  };
  
  export default Page;